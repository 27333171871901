import cntl from "cntl";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMatches, useParams } from "react-router";
import { useEffect, useCallback, useState, useRef, useMemo } from "react";

// Chart js
import { Line } from "react-chartjs-2";
import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  ArcElement,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";

// Icons
import { IconLoader2 } from "@tabler/icons-react";

// Components
import LayoutPage from "components/templates/LayoutPage";
import Typography from "components/atoms/typography/Typography";
import { openNotification } from "@dbox/core/actions/common/ui";
import { Select } from "@dbox/components";

// Constants
import { SIZE, NOTIFICATION_TYPES, NOTIFICATION_VARIANTS } from "@dbox/components/constants";

// Api
import { getChartEnumsYears, getChartOverviewYearlyDetails } from "@dbox/core/api/aldys/chart";
import { pluginSpaceBetweenLegendAndChart } from "utils/chart";
import { fetchCompanyEmployeeDetails } from "@dbox/core/api/aldys/company";

ChartJS.register(Title, Legend, Tooltip, ArcElement, BarElement, LinearScale, LineElement, PointElement, CategoryScale);

function MemberDetails() {
  const { t } = useTranslation();

  const chartRef = useRef();
  const matches = useMatches();
  const dispatch = useDispatch();
  const { employeeId } = useParams();
  const isDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [details, setDetails] = useState();
  const [years, setYears] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataChartBar, setDataChartBar] = useState({ data: {}, labels: [] });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const layoutCn = () => cntl`
    py-14
    xl:pt-0
  `;

  const selectAndChartCn = () => cntl`
    flex
    flex-col
    gap-y-10
  `;

  const chartBoxCn = ({ isDoughnut }) => cntl`
    h-96
    flex
    flex-col
    relative
    border
    rounded-md
    bg-gray-light-1
    dark:bg-gray-dark-1
    border-gray-light-6
    dark:border-gray-dark-6
    ${isDoughnut ? `lg:w-1/2` : `w-full`}
  `;

  const chartCn = () => cntl`
    h-full
  `;

  const loader2Wrapper = () => cntl`
    flex
    h-screen
    items-center
    justify-center
    bg-black/[.11]
  `;

  const loader2Cn = () => cntl`
    w-[5.5rem]
    h-[5.5rem]
    animate-spin
    text-primary-light-9
    dark:text-primary-dark-9
  `;

  const backAndChartCn = () => cntl`
    flex
    gap-y-4
    flex-col
  `;

  const cardsAndChartsCn = () => cntl`
    m-8
    flex
    gap-y-4
    flex-col
  `;
  const valueAndUdmCn = () => cntl`
    flex
    gap-x-1
    items-baseline
  `;

  const titleAndIconInfoCn = () => cntl`
  font-medium
  text-sm
  text-gray-light-11
  dark:text-gray-dark-11
  `;

  const selectCn = () => cntl`
    flex
    gap-x-4
    w-96
  `;

  const cardCn = () => cntl`
    flex
    flex-col
  `;

  const subCardCn = () => cntl`
    flex
    flex-col
    gap-1
    pr-6
  `;

  const subCardsCn = () => cntl`
    p-4 flex border rounded border-gray-light-4 dark:border-gray-dark-4 mt-4
  `;

  const breadcrumbs =
    matches
      .filter((match) => Boolean(match.handle?.crumb))
      .map((match) => match.handle.crumb())
      ?.at(-1) || [];

  const headerNavigation = { breadcrumbs: [...breadcrumbs, { label: "EMP-" + employeeId }] };

  const layoutProps = {
    headerNavigation,
    className: layoutCn(),
  };

  const colors = useMemo(
    () => ({
      backgroundBar: {
        scope1: isDarkMode ? "#05A2C2" : "#05A2C2",
        scope2: isDarkMode ? "#FBE32D" : "#FBE32D",
        scope3: isDarkMode ? "#D6409F" : "#D6409F",
        total: isDarkMode ? "#29A383" : "#29A383",
      },
      borderLine: {
        scope1: isDarkMode ? "#05A2C2" : "#05A2C2",
        scope2: isDarkMode ? "#FBE32D" : "#FBE32D",
        scope3: isDarkMode ? "#D6409F" : "#D6409F",
        total: isDarkMode ? "#29A383" : "#29A383",
      },
      legendColor: isDarkMode ? "#EDEEF0" : "#1C2024",
      textInsideColor: isDarkMode ? "#EDEEF0" : "#1C2024",
      ticksColor: isDarkMode ? "#ADB1B8" : "#60646C",
      lineColor: isDarkMode ? "#2E3035" : "#EBEBEF",
      labelsOutside: isDarkMode ? { top: "#EDEEF0", bottom: "#ADB1B8" } : { top: "#1C2024", bottom: "#60646C" },
    }),
    [isDarkMode]
  );

  const getEmissionScopeOfMonth = ({ data }) => {
    const total = Object.values(data)?.map(({ scope_1, scope_2, scope_3 }) => scope_1 + scope_2 + scope_3);

    return { total };
  };

  const optionsChartBarLine = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 24,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          borderColor: colors?.lineColor,
        },
        ticks: {
          color: colors?.ticksColor,
          font: { family: "Inter" },
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        stacked: true,
        grid: {
          borderColor: colors?.lineColor,
        },
        title: {
          text: t("page.dashboard.body.chart.label.yAxisBar"),
          display: true,
          color: colors?.legendColor,
          font: { family: "Inter" },
        },
        ticks: {
          color: colors?.ticksColor,
          font: { family: "Inter" },
        },
      },
    },
    plugins: {
      legend: {
        align: "start",
        position: "top",
        onClick: () => null,
        labels: { boxHeight: 14, boxWidth: 14, color: colors?.legendColor, font: { family: "Inter" } },
      },
    },
  };

  const dataOfChartBarLine = useMemo(() => {
    return {
      labels: dataChartBar?.labels,
      maintainAspectRatio: false,
      datasets: [
        {
          pointRadius: 2,
          data: dataChartBar?.data?.total,
          borderColor: colors.borderLine.total,
          borderWidth: 1,
          backgroundColor: colors.backgroundBar.total,
          label: t("page.dashboard.body.chart.label.total"),
        },
      ],
    };
  }, [t, dataChartBar?.data, dataChartBar?.labels, colors.borderLine.total, colors.backgroundBar.total]);

  const fetchChartEnumsYears = useCallback(
    async ({ ignore }) => {
      setIsLoading(true);
      try {
        if (!ignore) {
          const res = await getChartEnumsYears();

          if (res?.data) {
            const years = res.data?.map((year) => ({ label: year, value: year }));
            setYears(years);
          }
        }
      } catch (err) {
        if (err?.response?.status?.toString() === "500") {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.server_error"),
              message: t("notification.error.500.message.server_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        } else {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.generic_error"),
              message: t("notification.error.500.message.generic_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [t, dispatch]
  );

  const fetchChartOverviewYearlyDetails = useCallback(
    async ({ ignore, year, employeeId }) => {
      setIsLoading(true);
      try {
        if (!ignore) {
          const res = await getChartOverviewYearlyDetails({ year, employee_id: employeeId });

          const { total } = getEmissionScopeOfMonth({ data: res?.data });

          if (res?.data) {
            setDataChartBar({
              data: { total },
              labels: [
                t("page.dashboard.body.chart.label.january"),
                t("page.dashboard.body.chart.label.february"),
                t("page.dashboard.body.chart.label.march"),
                t("page.dashboard.body.chart.label.april"),
                t("page.dashboard.body.chart.label.may"),
                t("page.dashboard.body.chart.label.june"),
                t("page.dashboard.body.chart.label.july"),
                t("page.dashboard.body.chart.label.august"),
                t("page.dashboard.body.chart.label.september"),
                t("page.dashboard.body.chart.label.october"),
                t("page.dashboard.body.chart.label.november"),
                t("page.dashboard.body.chart.label.december"),
              ],
            });
          }
        }
      } catch (err) {
        if (err?.response?.status?.toString() === "500") {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.server_error"),
              message: t("notification.error.500.message.server_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        } else {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.generic_error"),
              message: t("notification.error.500.message.generic_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [t, dispatch]
  );

  const fetchEmployeeDetails = useCallback(
    async ({ ignore, employeeId }) => {
      setIsLoading(true);
      try {
        if (!ignore) {
          const res = await fetchCompanyEmployeeDetails(employeeId);
          setDetails(res?.employee);
        }
      } catch (err) {
        if (err?.response?.status?.toString() === "500") {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.server_error"),
              message: t("notification.error.500.message.server_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        } else {
          dispatch(
            openNotification({
              title: t("notification.error.500.title.generic_error"),
              message: t("notification.error.500.message.generic_error"),
              timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
              type: NOTIFICATION_TYPES.error,
              variant: NOTIFICATION_VARIANTS.toast,
            })
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [t, dispatch]
  );

  useEffect(() => {
    let ignore = false;

    fetchChartEnumsYears({ ignore });
    return () => {
      ignore = true;
    };
  }, [fetchChartEnumsYears]);

  useEffect(() => {
    let ignore = false;
    fetchChartOverviewYearlyDetails({ ignore, employeeId, year: selectedYear });
    fetchEmployeeDetails({ ignore, employeeId });
    return () => {
      ignore = true;
    };
  }, [fetchChartOverviewYearlyDetails, fetchEmployeeDetails, employeeId, selectedYear]);

  return (
    <>
      {isLoading ? (
        <div className={loader2Wrapper()}>
          <IconLoader2 className={loader2Cn()} />
        </div>
      ) : (
        <LayoutPage {...layoutProps}>
          <div className={cardsAndChartsCn()}>
            <div className={cardCn()}>
              <Typography size={SIZE["2xl"]}>{t(`${details?.first_name} ${details?.last_name}`)}</Typography>
              <div className={subCardsCn()}>
                <div className={subCardCn()}>
                  <div className={titleAndIconInfoCn()}>
                    <Typography size={SIZE.sm}>{t(`table.header.address`)}</Typography>
                  </div>
                  <div className={valueAndUdmCn()}>
                    <Typography
                      className={"font-semibold text-gray-light-12 dark:text-gray-dark-12"}
                      size={SIZE.sm}
                    >{`${details?.address?.street}, ${details?.address?.postcode}, ${details?.address?.city} (${details?.address?.region})`}</Typography>
                  </div>
                </div>
                <div className={subCardCn()}>
                  <div className={titleAndIconInfoCn()}>
                    <Typography size={SIZE.sm}>{t(`table.header.office`)}</Typography>
                  </div>
                  <div className={valueAndUdmCn()}>
                    <Typography className={"font-semibold text-gray-light-12 dark:text-gray-dark-12"} size={SIZE.sm}>
                      {details?.office?.name}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={selectAndChartCn()}>
              <div className={selectCn()}>
                <Select
                  name="years"
                  size={SIZE.md}
                  options={years}
                  className="w-full"
                  value={selectedYear}
                  label={t("select.label.filter_by_year")}
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                  }}
                />
              </div>
              <div className={backAndChartCn()}>
                <div className={chartBoxCn({ isDoughnut: false })}>
                  <div className={chartCn()}>
                    <Line
                      ref={chartRef}
                      data={dataOfChartBarLine}
                      options={optionsChartBarLine}
                      plugins={[pluginSpaceBetweenLegendAndChart]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutPage>
      )}
    </>
  );
}

export default MemberDetails;

import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getIsLoggedIn, getLoginVerificationState } from "@dbox/aldys-common/store/reducers/app.reducer";
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Members from "pages/main/Members";
import MemberDetails from "@dbox/aldys/src/pages/main/MemberDetails";

// Layout
const LayoutOnboarding = React.lazy(() => import("@dbox/aldys/src/components/templates/LayoutOnBoarding"));
const LayoutFullScreen = React.lazy(() => import("@dbox/aldys-common/components/templates/LayoutFullScreen"));
const LayoutWithMainSidebar = React.lazy(() => import("@dbox/aldys-common/components/templates/LayoutWithMainSidebar"));
const LayoutWithSettingsSidebar = React.lazy(() =>
  import("@dbox/aldys-common/components/templates/LayoutWithSettingsSidebar")
);

// Routing
const ProtectedRoute = React.lazy(() => import("@dbox/aldys-common/routes/ProtectedRoute"));

// Login, signup and reset passoword (page without login)
const Login = React.lazy(() => import("@dbox/aldys-common/pages/Login"));
const Signup = React.lazy(() => import("@dbox/aldys-common/pages/Signup"));
const SignupWelcome = React.lazy(() => import("@dbox/aldys-common/pages/SignupWelcome"));
const ResetPassword = React.lazy(() => import("@dbox/aldys-common/pages/ResetPassword"));
const LoginVerification = React.lazy(() => import("@dbox/aldys-common/pages/LoginVerification"));
const RequestRecoveryPassword = React.lazy(() => import("@dbox/aldys-common/pages/RequestRecoveryPassword"));

// Onboarding pages
const RootOnboarding = React.lazy(() => import("@dbox/aldys/src/pages/onboarding/RootOnboarding"));
const OnboardingStep1 = React.lazy(() => import("@dbox/aldys/src/pages/onboarding/OnboardingStep1"));
const OnboardingStep2 = React.lazy(() => import("@dbox/aldys/src/pages/onboarding/OnboardingStep2"));
const OnboardingStep3 = React.lazy(() => import("@dbox/aldys/src/pages/onboarding/OnboardingStep3"));
const OnboardingStep4 = React.lazy(() => import("@dbox/aldys/src/pages/onboarding/OnboardingStep4"));

// Core
const Root = React.lazy(() => import("@dbox/aldys/src/pages/Root"));

// Main
const MainLogs = React.lazy(() => import("@dbox/aldys/src/pages/main/Logs"));
const MainRecords = React.lazy(() => import("@dbox/aldys/src/pages/main/Records"));
const MainOffices = React.lazy(() => import("@dbox/aldys/src/pages/main/Offices"));
const MainProjects = React.lazy(() => import("@dbox/aldys/src/pages/main/Projects"));
const MainDashboard = React.lazy(() => import("@dbox/aldys/src/pages/main/Dashboard"));
const MainLogDetails = React.lazy(() => import("@dbox/aldys/src/pages/main/LogDetails"));
const MainMarketplace = React.lazy(() => import("@dbox/aldys/src/pages/main/Marketplace"));
const MainRecordDetails = React.lazy(() => import("@dbox/aldys/src/pages/main/RecordDetails"));
const MainCertifications = React.lazy(() => import("@dbox/aldys/src/pages/main/Certifications"));
const MainProductDetails = React.lazy(() => import("@dbox/aldys/src/pages/main/ProductDetails"));

// Settings
const SettingsPlans = React.lazy(() => import("@dbox/aldys/src/pages/settings/Plans"));
const SettingsProfile = React.lazy(() => import("@dbox/aldys/src/pages/settings/Profile"));
const SettingsOverview = React.lazy(() => import("@dbox/aldys/src/pages/settings/Overview"));
const SettingsReferral = React.lazy(() => import("@dbox/aldys/src/pages/settings/Referral"));
const SettingsInvoicing = React.lazy(() => import("@dbox/aldys/src/pages/settings/Invoicing"));
const SettingsMembers = React.lazy(() => import("@dbox/aldys/src/pages/settings/Members"));
const SettingsOfficeEdit = React.lazy(() => import("@dbox/aldys/src/pages/settings/OfficeEdit"));
const SettingsPlansIndex = React.lazy(() => import("@dbox/aldys/src/pages/settings/PlansIndex"));
const SettingsConnections = React.lazy(() => import("@dbox/aldys/src/pages/settings/Connections"));
const SettingsOfficeCreate = React.lazy(() => import("@dbox/aldys/src/pages/settings/OfficeCreate"));
const SettingsCheckoutPlan = React.lazy(() => import("@dbox/aldys/src/pages/settings/CheckoutPlan"));
const PurchasedCompleted = React.lazy(() => import("@dbox/aldys/src/pages/settings/PurchasedCompleted"));

const AldysRoutes = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const loginVerification = useSelector(getLoginVerificationState);
  const isVerificationCompleted = isLoggedIn && loginVerification?.step1 && loginVerification?.step2;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<ProtectedRoute isLoggedIn={isVerificationCompleted} />}>
          <Route element={<Root />}>
            <Route element={<LayoutWithMainSidebar />}>
              <Route path="/" element={<Navigate to="my-records" />} />
              <Route path="dashboard">
                <Route
                  index
                  handle={{
                    crumb: () => [
                      {
                        label: t("breadcrumbs.dashboard"),
                      },
                    ],
                  }}
                  element={
                    <Suspense fallback="Loading...">
                      <MainDashboard />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="logs">
                <Route
                  index
                  handle={{
                    crumb: () => [
                      {
                        label: t("breadcrumbs.logs"),
                      },
                    ],
                  }}
                  element={
                    <Suspense fallback="Loading...">
                      <MainLogs />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="certifications">
                <Route
                  index
                  handle={{
                    crumb: () => [
                      {
                        label: t("breadcrumbs.certifications"),
                      },
                    ],
                  }}
                  element={
                    <Suspense fallback="Loading...">
                      <MainCertifications />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="marketplace">
                <Route
                  index
                  handle={{
                    crumb: () => [
                      {
                        label: t("breadcrumbs.marketplace"),
                      },
                    ],
                  }}
                  element={
                    <Suspense fallback="Loading...">
                      <MainMarketplace />
                    </Suspense>
                  }
                />
                <Route
                  path=":id"
                  handle={{
                    crumb: () => [
                      {
                        label: t("breadcrumbs.marketplace"),
                      },
                    ],
                  }}
                  element={
                    <Suspense fallback="Loading...">
                      <MainProductDetails />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="projects">
                <Route
                  index
                  handle={{
                    crumb: () => [
                      {
                        label: t("breadcrumbs.projects"),
                      },
                    ],
                  }}
                  element={
                    <Suspense fallback="Loading...">
                      <MainProjects />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="records"
                handle={{
                  crumb: () => [
                    {
                      label: t("breadcrumbs.records"),
                    },
                  ],
                }}
                element={
                  <Suspense fallback="Loading...">
                    <MainRecords />
                  </Suspense>
                }
              />
              <Route
                path="my-records"
                handle={{
                  crumb: () => [
                    {
                      label: t("breadcrumbs.my_records"),
                    },
                  ],
                }}
                element={
                  <Suspense fallback="Loading...">
                    <MainRecords />
                  </Suspense>
                }
              />
              <Route path="employees">
                <Route
                  index
                  handle={{
                    crumb: () => [
                      {
                        to: "..",
                        label: t("breadcrumbs.employees"),
                      },
                    ],
                  }}
                  element={
                    <Suspense fallback="Loading...">
                      <Members />
                    </Suspense>
                  }
                />
                <Route
                  path=":employeeId"
                  handle={{
                    crumb: () => [
                      {
                        to: "..",
                        label: t("breadcrumbs.employees"),
                      },
                    ],
                  }}
                  element={
                    <Suspense fallback="Loading...">
                      <MemberDetails />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="all-offices"
                handle={{
                  crumb: () => [
                    {
                      label: t("breadcrumbs.offices"),
                    },
                  ],
                }}
                element={
                  <Suspense fallback="Loading...">
                    <MainOffices />
                  </Suspense>
                }
              />
              <Route path="offices/:officeId">
                <Route path="records">
                  <Route
                    index
                    element={
                      <Suspense fallback="Loading...">
                        <MainRecords />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":recordId"
                    handle={{
                      crumb: () => [
                        {
                          to: "..",
                          label: t("breadcrumbs.records"),
                        },
                      ],
                    }}
                    element={
                      <Suspense fallback="Loading...">
                        <MainRecordDetails />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path="logs">
                  <Route
                    index
                    element={
                      <Suspense fallback="Loading...">
                        <MainLogs />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":logId"
                    handle={{
                      crumb: () => [
                        {
                          to: "..",
                          label: t("breadcrumbs.logs"),
                        },
                      ],
                    }}
                    element={
                      <Suspense fallback="Loading...">
                        <MainLogDetails />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>
            <Route element={<LayoutWithSettingsSidebar />}>
              <Route path="settings" element={<Navigate to="/settings/overview" />} />
              <Route path="settings">
                <Route
                  index
                  path="overview"
                  element={
                    <Suspense fallback="Loading...">
                      <SettingsOverview />
                    </Suspense>
                  }
                />
                <Route
                  path="members"
                  element={
                    <Suspense fallback="Loading...">
                      <SettingsMembers />
                    </Suspense>
                  }
                />
                <Route path="plans" element={<SettingsPlans />}>
                  <Route
                    index
                    element={
                      <Suspense fallback="Loading...">
                        <SettingsPlansIndex />
                      </Suspense>
                    }
                  />
                  <Route path="checkout">
                    <Route path="plan" element={<SettingsCheckoutPlan />} />
                    <Route
                      path="completed"
                      element={
                        <Suspense fallback="Loading...">
                          <PurchasedCompleted />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="referral"
                  element={
                    <Suspense fallback="Loading...">
                      <SettingsReferral />
                    </Suspense>
                  }
                />
                <Route
                  path="invoicing"
                  element={
                    <Suspense fallback="Loading...">
                      <SettingsInvoicing />
                    </Suspense>
                  }
                />
                <Route
                  path="profile"
                  element={
                    <Suspense fallback="Loading...">
                      <SettingsProfile />
                    </Suspense>
                  }
                />
                <Route
                  path="connections"
                  element={
                    <Suspense fallback="Loading...">
                      <SettingsConnections />
                    </Suspense>
                  }
                />
                <Route path="office">
                  <Route
                    path="create"
                    element={
                      <Suspense fallback="Loading...">
                        <SettingsOfficeCreate />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id/edit"
                    element={
                      <Suspense fallback="Loading...">
                        <SettingsOfficeEdit />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="onboarding" element={<RootOnboarding />}>
            <Route element={<LayoutOnboarding />}>
              <Route
                path="step1"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep1 />
                  </Suspense>
                }
              />
              <Route
                path="step2"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep2 />
                  </Suspense>
                }
              />
              <Route
                path="step3"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep3 />
                  </Suspense>
                }
              />
              <Route
                path="step4"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep4 />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* No login */}
        <Route path="/" element={<LayoutFullScreen />}>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="password/reset" element={<ResetPassword />} />
          <Route path="signup/success" element={<SignupWelcome />} />
          <Route path="login/verification" element={<LoginVerification />} />
          <Route path="/password/recovery" element={<RequestRecoveryPassword />} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default AldysRoutes;
